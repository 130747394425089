import React from 'react';
import './brand.css';
import { Link } from "react-router-dom";

const Brandtapas = () => {
    return(
        <section className='card__showcase'>
        <div className='dark__box'/>

        <div className='showcase-title-center'>
            <h1>Some of my Other Work</h1>
        </div>

        <ul className="thumbnail-list">
            <li className="thumbnail-item">
            <Link to= "/tapas">
                <div className="card one">
                    <div className='inner-card'>
                        <div className='inner-text'>
                        <i><b><h3>2020 - Present</h3></b></i>
                        <h1>Tapas Media</h1>
                        <br/>
                        <h3 className='subtext'>Webcomic Coloring Assistant</h3>
                        </div>
                    </div>
                </div>
            </Link>
            </li>
            <li className="thumbnail-item">
            <Link to= "/spectraloverdrive">
                <div className="card three">
                    <div className='inner-card'>
                        <div className='inner-text'>
                        <i><b><h3>2022</h3></b></i>
                        <h1>Spectral Overdrive</h1>
                        <br/>
                        <h3 className='subtext'>Game Developer and Designer</h3>
                        </div>
                    </div>
                </div>
            </Link>
            </li>
        </ul>

        <div className='dark__box_new'/>

        </section>
    )
}

export default Brandtapas