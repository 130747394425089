import React from 'react';
import './portfolio.css';
import './tapascards.scss';
import TapasLogo from '../../assets/card_csuf.png';
import pizza1 from '../../assets/PizzaImg1.png';
import pizza2 from '../../assets/PizzaImg2.png';
import pizza3 from '../../assets/PizzaImg3.png';
import covid1 from '../../assets/CovidImg1.png';
import covid2 from '../../assets/CovidImg2.png';
import covid3 from '../../assets/CovidImg3.png';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

const CSUF = () => {
    const onBeforeSlide = (detail) => {
        const { index, prevIndex } = detail;
        console.log(index, prevIndex);
    };
    return(
        <section >
            <div className="csuf-banner">
                <img className="banner-image" src={TapasLogo} alt="CSUF Logo" />    
            </div >

            <div className="main-portfolio">

            <div className="dark__box"></div>

            <div className="portfolio-text">

                <div>
                    <h1>CSU Fullerton College of Computer Science</h1>
                    <h4 className="job-title">Web Developer and Designer</h4>
                    <b>August 2021 - December 2022</b>
                </div>

                <div>
                    <h2>Team</h2>
                    <p>
                        Worked in two development teams of four. 
                    </p>
                </div>

                <div>
                <h2>The Challenge</h2>
                <p>
                California State University, Fullerton is a public university in Fullerton, 
                California, and is one of the largest CSUs in all of California. Teams of 
                student developers at the CSU Fullerton College of Computer Science were 
                tasked with developing various software from scratch.
                </p>
                </div>

                <div>
                <p>
                    I have contributed as a web developer to applications such as <b>Covid Dashboard</b> and <b>Pizza Radar</b>.
                </p>
                </div>

                <div>
                <h2>Lead Developer — Web Application “Covid Dashboard”</h2>
                    <ul className = "portfolio-list">
                        <li>Practiced full-stack Agile development, including scrums, to create a React-based web application. </li>
                        <li>Pulled live data from a Corona Virus database API to showcase on the dashboard using node.js. </li>
                        <li>Utilized Javascript and CSS to display the live data of tests, recoveries, and deaths on a daily and total basis in the form of graphs.</li>
                    </ul>
                </div>
            
            <div className='center-gallery'>
            <LightGallery licenseKey={1111-1111-111-1111} elementClassNames="custom-wrapper-class" onBeforeSlide={onBeforeSlide}>
                <a href={covid1}>
                    <img alt="Main Page of Covid Dashboard" className='lightbox-img' src={covid1} />
                </a>
                <a href={covid2}>
                    <img alt="About Page of Covid Dashboard" className='lightbox-img' src={covid2}/>
                </a>
                <a href={covid3}>
                    <img alt="Example of a Data Source for the API" className='lightbox-img' src={covid3} />
                </a>
            </LightGallery>
            </div>

                <div>
                <h2>Developer — Web Application “Pizza Radar”</h2>
                    <ul className = "portfolio-list">
                        <li>Designed a front-end interface for the primary webpage with CSS, Photoshop, and HTML. </li>
                        <li>Implemented Google Maps and Yelp API to find local pizza places within the area. </li>
                        <li>Assisted and communicated with other development teams to create API support for development.</li>
                        <li>Utilized MySQL and PHP to establish a login and registration system.</li>
                    </ul>
                </div>

            <div className='center-gallery'>
            <LightGallery licenseKey={1111-1111-111-1111} elementClassNames="custom-wrapper-class" onBeforeSlide={onBeforeSlide}>
                <a href={pizza1}>
                    <img alt="Main Page of Pizza Radar" className='lightbox-img' src={pizza1} />
                </a>
                <a href={pizza2}>
                    <img alt="Login Page of Pizza Radar" className='lightbox-img' src={pizza2} />
                </a>
                <a href={pizza3}>
                    <img alt="Registration Page of Pizza Radar" className='lightbox-img' src={pizza3} />
                </a>
            </LightGallery>
            </div>

                <div>
                <h2>Versed in Agile Development</h2>
                    <ul className = "portfolio-list">
                        <li>Coordinated and documented remote scrums for a team of four and helped organize individual tasks while periodically updating documentation. </li>
                        <li>Participated in presentations dedicated to outlining progress made between scrums.</li>
                        <li>Practiced both iterative and incremental development in addition to collaborating with software testers.</li>
                    </ul>
                </div>

                <div>
                <h2>Reflection</h2>
                    <p>
                    During my experience as a developer for the college for computer science, I built a strong foundation of skills that will allow me to thrive in an agile 
                    development environment. Additionally, I now know about full-stack development and have an even firmer grasp of front-end development.
                    </p>
                </div>

            </div>
            
            </div>
        </section>
    )
}

export default CSUF