import React from 'react';
import './portfolio.css';
import './tapascards.scss';
import TapasLogo from '../../assets/card_spectraloverdrive.png';
import SOgif1 from '../../assets/SO1.gif';
import SOgif2 from '../../assets/SO2.gif';
import SOgif3 from '../../assets/SO3.gif';
import SOsite1 from '../../assets/SOsite2.png';
import SOsite2 from '../../assets/SOsite1.png';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';


const Spectral = () => {
    const onBeforeSlide = (detail) => {
        const { index, prevIndex } = detail;
        console.log(index, prevIndex);
    };
    return(
        <section >
            <div className="spectral-banner">
                <img className="banner-image" src={TapasLogo} alt="Spectral Overdrive Logo" />    
            </div >

            <div className="main-portfolio">

            <div className="dark__box"></div>

            <div className="portfolio-text">

                <div>
                    <h1>Dallas Society of Play</h1>
                    <h4 className="job-title">Game Developer and Designer </h4>
                    <b>October 2022</b>
                </div>

                <div>
                    <h2>Team</h2>
                    <p>
                        Solo developer with a group of playtesters.
                    </p>
                </div>

                <div>
                <h2>The Challenge</h2>
                <p>
                Society of play is a Texas-based non-profit organization for game developers of varying backgrounds. 
                They provide a means for hobbyists and professionals to network and share their passion for game development. 
                In October, they hosted a game development competition called a game jam in which participants had to create a 
                game within two weeks.
                </p>
                </div>

                <div>
                <p>
                    I acted as the sole developer for <b>Spectral Overdrive</b>.
                </p>
                </div>

                <div>
                <h2>Sole Developer — Game Application “Spectral Overdrive”</h2>
                    <ul className = "portfolio-list">
                        <li>Designed and developed a Windows application within a two-week deadline and ranked 2nd in a game development contest consisting of 113 entries and 875 votes. </li>
                        <li>Constructed a fully interactable interface to traverse the game menus with smooth transitions between game scenes.</li>
                        <li>Created a custom particle effect system for player and enemy objects and finite state machines for player and enemy behavior. </li>
                        <li>Programmed a tutorial portion for the game that includes a complete dialogue system that teaches the player core game mechanics. </li>
                    </ul>
                </div>

            <div className='center-gallery'>
            <LightGallery 
            licenseKey={1111-1111-111-1111}
            id="light-gallery" onBeforeSlide={onBeforeSlide}>
                <a href={SOgif1}>
                    <img alt="Game Titlescreen" className='lightbox-img' src={SOgif1} />
                </a>
                <a href={SOgif2}>
                    <img alt="Main Gameplay" className='lightbox-img' src={SOgif2}/>
                </a>
                <a href={SOgif3}>
                    <img alt="Game Tutorial" className='lightbox-img' src={SOgif3} />
                </a>
            </LightGallery>
            </div>

                <div>
                <h2>Working with a Community</h2>
                    <ul className = "portfolio-list">
                        <li>Led remote presentations to pitch a game and discussed the competition with other game jam participants.</li>
                        <li>Provided and received constructive criticism for game entries in the game jam after playtesting.</li>
                        <li>Conducted QA testing with peers, made changes according to player feedback, and continued to fix bugs after publishing the game on an online marketplace. </li>
                    </ul>
                </div>

                <div className="spectral-container">
                    <div className="spectral-gallery">
                        
                        <div className="spectral-card">
                        <a href="https://itch.io/jam/revival-jam-2/rate/1745622" target="_blank" rel="noopener noreferrer">
                        <img src={SOsite1} alt="Gamejam Submission"/>
                        <div className="spectral-info">
                            <h4 className="tapas-title">Gamejam Submission</h4>
                        </div>
                        </a>
                        </div>

                        <div className="spectral-card">
                        <a href="https://badsprite.itch.io/spectral-overdrive" target="_blank" rel="noopener noreferrer">
                        <img src={SOsite2} alt="Itch.io Page"/>
                        <div className="spectral-info">
                            <h4 className="tapas-title">Game Itch.io Page</h4>
                        </div>
                        </a>
                        </div>
                    </div>
                </div>

                <div>
                <h2>Reflection</h2>
                    <p>
                    My experience creating a game for this competition has significantly impacted me. 
                    I got to hone my ability to work with deadlines while having meaningful interactions with my peers. 
                    From presenting my ideas to commenting on the concepts of others, each conversation, 
                    I had with the other participants built my appreciation for the development process. 
                    And I'll carry those sentiments with me to future projects.
                    </p>
                </div>

            </div>
            
            </div>
        </section>
    )
}

export default Spectral