import React from 'react';
import './portfolio.css';
import './tapascards.scss';
import './about.css'
import AboutPic from '../../assets/aboutmepic.png';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';


const About = () => {
    return(
        <section >
            <div className="main-portfolio main-about">

            <div className="dark__box"></div>

            <div className="about-container section">
            
            <div className="nav2">
                <img src={AboutPic} className="aboutpic" alt=""/>
            </div>
    
            <div className="tagtext" id="ABOUT">
    
            
            <div className='textbox'>
                <h1>
                    Hello! I'm Kenny Tran.
                </h1>
                <h2>
                So who is this guy? 🤔
                </h2>
                <p>
                I'm your local developer, artist, and indie game enthusiast. 
                I've been programming since high school, putting hours and days into making my own video games and websites. 
                Through my early development experience, I've become fascinated with everything involving visual and interactive design, 
                so I decided to become a developer, and here I am. Excited and ready to show the world what I have to offer!
                </p> 
            </div>
            
            </div>
            </div>
            
            </div>
        </section>
    )
}

export default About