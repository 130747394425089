import React from 'react';
import './brand.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faSquareGithub } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { Link } from "react-router-dom";

const Brand = () => {
    return(
        <section className='card__showcase'>
        <div className='dark__box'/>

        <div className='showcase-title'>
            <h1>Some of my Previous Work</h1>
        </div>

        <ul className="thumbnail-list">
            <li className="thumbnail-item">
            <Link to= "/csuf">
                <div className="card two">
                    <div className='inner-card'>
                    <div className='inner-text'>
                        <i><b><h3>2021</h3></b></i>
                        <h1>CSU Fullerton</h1>
                        <br/>
                        <h3 className='subtext'>Web Developer and Designer</h3>
                        </div>
                    </div>
                </div>
            </Link>
            </li>
            <li className="thumbnail-item">
            <Link to= "/tapas">
                <div className="card one">
                    <div className='inner-card'>
                        <div className='inner-text'>
                        <i><b><h3>2020 - Present</h3></b></i>
                        <h1>Tapas Media</h1>
                        <br/>
                        <h3 className='subtext'>Webcomic Coloring Assistant</h3>
                        </div>
                    </div>
                </div>
            </Link>
            </li>
            <li className="thumbnail-item">
            <Link to= "/spectraloverdrive">
                <div className="card three">
                    <div className='inner-card'>
                        <div className='inner-text'>
                        <i><b><h3>2022</h3></b></i>
                        <h1>Spectral Overdrive</h1>
                        <br/>
                        <h3 className='subtext'>Game Developer and Designer</h3>
                        </div>
                    </div>
                </div>
            </Link>
            </li>
        </ul>

        <div className='showcase-links'>
            <a href='https://github.com/kennykytran' target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faSquareGithub} className="showcase-icon"/>
            </a>
            <a href='https://www.linkedin.com/in/kennykytran/' target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedin} className="showcase-icon"/>
            </a>
            <a href='mailto:kennykytran@gmail.com' target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faSquareEnvelope} className="showcase-icon"/>
            </a>
        </div>

        <div className='dark__box'/>

        </section>
    )
}

export default Brand