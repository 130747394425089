import React from 'react';
import './header.css';
import selfportrait from '../../assets/self_port.png';

const Header = () => {
    return(
    <div className="header__container">    
    <div className="header section__padding" id="home">
        <div className="header-image">
            <img src={selfportrait} alt="Self Portrait of Kenny Tran"/>
        </div>
        <div className="header-content">
            <h1 className="gradient__text">Hello, I&apos;m Kenny!</h1>
            <p>A developer, artist, and cyan enthusiast here to bring your ideas the visual flair they deserve! I&apos;m currently looking for work!</p>
        </div>

       

    </div>
    </div>
    )
}

export default Header