import React from 'react';
import './portfolio.css';
import './tapascards.scss';
import TapasLogo from '../../assets/card_tapas.png';
import work1 from '../../assets/TapasWorkC.png';
import work2 from '../../assets/TapasWorkB.jpg';
import work3 from '../../assets/TapasWorkA1.png';

const Tapas = () => {
    return(
        <section >
            <div className="tapas-banner">
                <img className="banner-image" src={TapasLogo} alt="Tapas Logo" />    
            </div >

            <div className="main-portfolio">

            <div className="dark__box"></div>

            <div className="portfolio-text">

                <div>
                    <h1>Tapas Entertainment</h1>
                    <h4 className="job-title">Freelance Comic Colorist</h4>
                    <b>July 2020 - Present</b>
                </div>

                <div>
                    <h2>Team</h2>
                    <p>
                        Worked in multiple teams of at least 6, each consisting of artists, editors, and production managers. 
                    </p>
                </div>

                <div>
                <h2>The Challenge</h2>
                <p>
                Tapas is a webcomic platform reported to have over 2 million active users per month. 
                Tapas Entertainment then launched Studio Tapas in 2020, and freelancers were tasked with 
                supporting numerous projects, working to create premium webcomics and novels for the platform.
                </p>
                </div>

                <div>
                <p>
                    I provided art assistance to comics such as <b>The Boy Who Murdered Love</b>, <b>The Princess and the Demon</b>, and <b>The Witch's Throne</b>.
                </p>
                </div>

                <div className="tapas-container">
                <div className="tapas-gallery">
                    
                    <div className="tapas-card">
                    <a href='https://tapas.io/series/The-Boy-Who-Murdered-Love/info' target="_blank" rel="noopener noreferrer">
                    <img src={work1} alt="The Boy Who Murdered Love"/>
                    <div className="tapas-info">
                        <h4 className="tapas-title">The Boy Who Murdered Love</h4>
                    </div>
                    </a>
                    </div>
                   
                    <div className="tapas-card">
                    <a href='https://tapas.io/series/The-Princess-and-The-Demon/info' target="_blank" rel="noopener noreferrer">
                    <img src={work2} alt="The Princess and the Demon"/>
                    <div className="tapas-info">
                        <h4 className="tapas-title">The Princess and the Demon</h4>
                    </div>
                    </a>
                    </div>
                    <div className="tapas-card">
                    <a href='https://tapas.io/series/thewitchsthrone/info' target="_blank" rel="noopener noreferrer">
                    <img src={work3} alt="The Witch's Throne"/>
                    <div className="tapas-info">
                        <h4 className="tapas-title">The Witch's Throne</h4>
                    </div>
                    </a>
                    </div>
                </div>
            </div>

                <div>
                <h2>Skilled in Production</h2>
                    <ul className = "portfolio-list">
                        <li>Work on projects concurrently and conduct self-priority management between different 
                            production schedules to provide coloring assistance to multiple online comics. </li>
                        <li>Collaborate and communicate in a fast-paced work environment with an interdisciplinary 
                            team by following coloring directions and providing revisions based on critique. </li>
                        <li>Coordinate with clients of varying time zones to establish turn-in times for optimal workflow.</li>
                    </ul>
                </div>

                <div>
                <h2>Flexibility Between Multiple Projects</h2>
                    <ul className = "portfolio-list">
                        <li>Worked on different projects with different needs and production cycles. </li>
                        <li>Maintained consistency between files and organized them accordingly for ease of use across all team disciplines.</li>
                        <li>Provided various methods of art assistance, from flat colors to fully rendered art.</li>
                    </ul>
                </div>

                <div>
                <h2>Reflection</h2>
                    <p>
                    Working with the people at Tapas Entertainment has been incredible. 
                    I am so honored to work alongside so many talented people. My experience 
                    as a colorist significantly strengthened my organizational and time management skills, 
                    and I could carry these skills to other positions beyond the art industry.
                    </p>
                </div>

            </div>
            
            </div>
        </section>
    )
}

export default Tapas