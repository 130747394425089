import React from 'react'

import './App.css';

import { Navbar, Brand, Brandtapas, Brandcsuf, Brandspectral } from './components';
import { Footer, Header, CSUF, Spectral, Tapas, About } from './containers';
import ScrollToTop from './ScrollToTop';
// eslint-disable-next-line
import { HashRouter as Router, Route, Routes } from 'react-router-dom'

const App = () => {
    return(
        <div className='App'>
            <Navbar/>
            <ScrollToTop />
            <Routes>
                <Route exact path="/" element={<><Header/><Brand/></>}/>
                <Route exact path="about" element={<><About/></>}/>
                <Route exact path="tapas" element={<><Tapas/><Brandtapas/></>}/>
                <Route exact path="spectraloverdrive" element={<><Spectral/><Brandspectral/></>}/>
                <Route exact path="csuf" element={<><CSUF/><Brandcsuf/></>}/>
                <Route exact path="*" element={<><Header/><Brand/></>}/>
            </Routes>
            <Footer/>
        </div>
    )
}

export default App