import React from 'react';
import './footer.css';
import { Link } from "react-router-dom";
import resume from '../../assets/KennyTranResume.pdf';

const Footer = () => (
  <footer className="footer.contain section__padding">

    <div className="footer-links">
      <div className="footer-links_logo">
        <h1 className="footer-heading">Kenny Ky Tran</h1>
      </div>
      <div className="footer-links_div">
        <h4>Links</h4>
        <Link to= "/">
        <p>Home</p>
        </Link>
        <Link to= "/about">
        <p>About</p>
        </Link>
        <a href='https://drive.google.com/file/d/1Y4NK_g9ww6sjIeTjNeB8j6AmtXj-sniP/view?usp=sharing' target="_blank" rel="noopener noreferrer" >
        <p>Resume</p>
        </a>
      </div>
      <div className="footer-links_div">
        <h4>Portfolio</h4>
        <Link to= "/tapas">
        <p>Tapas</p>
        </Link>
        <Link to= "/csuf">
        <p>CSU Fullerton</p>
        </Link>
        <Link to= "/spectraloverdrive">
        <p>Spectral Overdrive</p>
        </Link>
      </div>
      <div className="footer-links_div">
        <h4>Get in touch</h4>
        <a href='https://github.com/kennykytran' target="_blank" rel="noopener noreferrer">
        <p>Github</p>
        </a>
        <a href='https://www.linkedin.com/in/kennykytran/' target="_blank" rel="noopener noreferrer">
        <p>Linkedin</p>
        </a>
        <a href='mailto:kennykytran@gmail.com' target="_blank" rel="noopener noreferrer">
        <p>Email</p>
        </a>
      </div>
    </div>

    <div className="footer-copyright">
      <p>@2023 Kenny Tran. All rights reserved.</p>
    </div>
  </footer>
);

export default Footer;